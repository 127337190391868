import {Button, Col, CustomInput, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {SelectControllerRHF, TextControllerRHF} from "../../../generic/fields";
import {t} from "i18next";
import {requiredRule} from "../../../common/reactHookFormsFields/rules";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useDefaultEndpoints} from "../../../../lib/api/useDefaultEndpoints";
import {API_URL} from "../../../../lib/api/useApi";
import {useHistory, useParams} from "react-router-dom";
import {TableCard} from "../../../generic/cards";
import {CopyLink} from "../../../generic/buttons";
import {withBreadcrumbs} from "../../../common/hoc/Breadcrumbs";
import usePages from "../usePages";
import ControllerRHF from "../../../generic/fields/ControllerRHF";
import LetterFormFields from "./LetterFormFields";
import PageFormFields from "./PageFormFields";

const Page = withBreadcrumbs(({entity}) => {
    const history = useHistory();
    const {id} = useParams();

    const [projects, setProjects] = useState();
    const [templates, setTemplates] = useState();
    const [coordinators, setCoordinators] = useState();
    const [baseAddresses, setBaseAddresses] = useState();
    const [areas, setAreas] = useState();
    const [formats, setFormats] = useState();

    const form = useForm();
    const {handleSubmit, reset, control, errors, setValue, watch} = form;

    const {getEntry, createEntry, editEntry} = useDefaultEndpoints('pages', true);
    const {getEntries: getProjects} = useDefaultEndpoints('projects');
    const {getEntries: getTemplates} = useDefaultEndpoints('templates');
    const {getEntries: getCoordinators} = useDefaultEndpoints('coordinators');
    const {getEntries: getBaseAddresses} = useDefaultEndpoints('base-addresses');
    const {getEntries: getAreas} = useDefaultEndpoints('areas');
    const {getEntries: getFormats} = useDefaultEndpoints('formats');
    const {findAndCountEntries} = useDefaultEndpoints('constructor-directories');

    const isLettersEntity = entity === 'letters';

    const {getPageFullURL} = usePages();
    const pageFullURL = getPageFullURL(
        projects?.find(project => project.id == watch('project')),
        watch('key')
    );

    const getProjectsOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.pickProject')}</option>,
        projects?.map(project =>
            <option value={project.id} key={project.key}>{project.label}</option>
        )
    ])

    const getTemplatesOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.pickTemplate')}</option>,
        templates?.map(template =>
            <option value={template.id} key={template.key}>{template.label}</option>
        )
    ])

    const getCoordinatorsOptions = () => ([
        <option value={''} disabled>{t('constructor.pages.coordinator')}</option>,
        coordinators?.map(coordinator =>
            <option value={coordinator.id} key={coordinator.id}>{coordinator.name}</option>
        )
    ])

    const getBaseAddressesOptions = () => ({ options: baseAddresses?.map(address =>
            ({label: address.name, value: address.id})
        )})

    const getAreasOptions = () => ({ options: areas?.map(area =>
        ({label: area.name, value: area.id})
    )})

    const getFormatsOptions = () => ({ options: formats?.map(format =>
        ({label: format.name, value: format.id})
    )})

    const onCreateOrUpdate = (data) => id
        ? editEntry(id, data).then( () => toast.success(t('common.success')) )
        : createEntry(data).then( () => history.push(`/constructor/${entity ? entity : 'pages'}/`) );

    const onSubmit = handleSubmit(data => {
        const {logo, base_addresses, areas, formats, ...dataToSend} = data;
        if (entity === 'letters') dataToSend.is_letter = true;
        onCreateOrUpdate({
            ...dataToSend,
            base_addresses: base_addresses.map((address) => address.value),
            areas: areas ? areas.map((area) => area.value) : [],
            formats: formats ? formats.map((format) => format.value): [],
            files: logo
        })
    });

    const onLoadDirectory = (query, opts, { offset: prevOffset, limit: prevLimit, query: prevQuery }) => {
        const reqOpts = {"_limit": prevLimit, "_start": prevOffset};
        if (query) {
            reqOpts.description_contains = query;
        }

        return findAndCountEntries(reqOpts).then(res => {
            const offset = query === prevQuery ? prevOffset + 10 : 0;
            const hasMore = (offset + prevLimit) <= res.total;

            return {
                options: res.data?.map(i => ({ value: i.description, label: i.description })),
                hasMore,
                additional: {
                    offset,
                    query,
                    limit: prevLimit,
                }
            }
        }).catch(() => ({ options: [], hasMore: false }));
    }

    useEffect(() => {
        if (id) {
            getEntry(id).then(res => {
                Object.keys(res).forEach(key => {
                    if (res[key] === null) {
                        delete res[key];
                    }
                });

                if (res.project){
                    res.project = res.project.id
                }
                if (res.template){
                    res.template = res.template.id
                }

                reset({...res, coordinator: res.coordinator ? res.coordinator.id : undefined,
                    logo: res.logo ? {...res.logo, preview: API_URL + res.logo.url} : {},
                    base_addresses: res?.base_addresses ? res.base_addresses.map((address) => ({label: address.name, value: address.id})) : {},
                    areas: res?.areas ? res.areas.map((area) => ({label: area.name, value: area.id})) : {},
                    formats: res?.formats ? res.formats.map((format) => ({label: format.name, value: format.id})) : {}
                });
            })
        }

        getProjects().then(setProjects);
        getTemplates().then(setTemplates);
        getCoordinators().then(setCoordinators);
        getBaseAddresses().then(setBaseAddresses);
        getAreas().then(setAreas);
        getFormats().then(setFormats);
    }, [id]);

    return <TableCard>
        <div className={"row justify-content-end mb-3"}>
            <div className={"col-"}>
                <Button color="success" size={'sm'} className={'btn-rounded'} onClick={onSubmit}>
                    {t('common.save')}
                </Button>
            </div>
        </div>
        <Form>
            <Row className={'align-items-end'}>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>URL External</Label>
                        <CopyLink href={pageFullURL(true)} showLink={false}/>
                        <Input name="url" value={pageFullURL(true)} disabled />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Label>URL for testing</Label>
                        <CopyLink href={pageFullURL(false)} showLink={false}/>
                        <Input name="url" value={pageFullURL(false)} disabled />
                    </FormGroup>
                </Col>
                <Col lg={4} md={6} xs={12} className={'mb-1'}>
                    <FormGroup>
                        <ControllerRHF
                            name={'is_public'}
                            form={form}
                            render={(
                                {value, name, onChange}
                            ) =>
                                <CustomInput
                                    type="checkbox"
                                    className={'custom-switch'}
                                    id={name}
                                    name={name}
                                    label={t("constructor.public")} checked={value || false}
                                    onChange={(e) => onChange(e.target.checked)} />
                            }
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div className='pages-divider'/>
            <Row>
            <Col lg={4} md={6} xs={12}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={'key'} control={control} errors={errors} placeholder={t('constructor.keyPlaceholder')}
                        label={t('constructor.key')} isRequired defaultValue='' rules={requiredRule} isDeleteSpaces
                    />
                </FormGroup>
            </Col>
            <Col lg={4} md={6} xs={12}>
                <FormGroup>
                    <SelectControllerRHF
                        inputName={"project"} control={control} defaultValue='' options={getProjectsOptions()}
                        label={t('constructor.pages.project')} isRequired errors={errors} rules={requiredRule}
                    />
                </FormGroup>
            </Col>
            <Col lg={4} md={6} xs={12}>
                <FormGroup>
                    <SelectControllerRHF
                        inputName={"template"} control={control} defaultValue='' options={getTemplatesOptions()}
                        label={t('constructor.pages.template')} isRequired errors={errors} rules={requiredRule}
                    />
                </FormGroup>
            </Col>
            <Col lg={4} md={6} xs={12}>
                <FormGroup>
                    <TextControllerRHF
                        inputName={'label'} control={control} errors={errors} label={t('constructor.label')}
                        isRequired defaultValue='' rules={requiredRule} placeholder={t('constructor.pages.labelPlaceholder')}
                    />
                </FormGroup>
            </Col>
            </Row>
            <div className='pages-divider'/>
                {isLettersEntity ? (
                        <LetterFormFields
                            form={form}
                            control={control}
                            errors={errors}
                            getProjectsOptions={getProjectsOptions}
                            getTemplatesOptions={getTemplatesOptions}
                            getBaseAddressesOptions={getBaseAddressesOptions}
                            getFormatsOptions={getFormatsOptions}
                            getAreasOptions={getAreasOptions}
                            getCoordinatorsOptions={getCoordinatorsOptions}
                            requiredRule={requiredRule}
                        />
                    ) : (
                        <PageFormFields
                            form={form}
                            control={control}
                            errors={errors}
                            getProjectsOptions={getProjectsOptions}
                            getTemplatesOptions={getTemplatesOptions}
                            getBaseAddressesOptions={getBaseAddressesOptions}
                            getFormatsOptions={getFormatsOptions}
                            getAreasOptions={getAreasOptions}
                            getCoordinatorsOptions={getCoordinatorsOptions}
                            requiredRule={requiredRule}
                            pageFullURL={pageFullURL}
                            onLoadDirectory={onLoadDirectory}
                            setValue={setValue}
                        />
                    )}
        </Form>
    </TableCard>
})

export default Page