import React from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {Switch} from "../../../../generic/buttons";
import PropTypes from 'prop-types';
import HideUntilField from "../../../../common/formElements/hideUntilField";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import TelegramOptions from '../../../../common/formElements/TelegramOptions';

const Settings = ({state, dispatch, control, errors, isSlot = false}) => {
    const setData = e => dispatch({type: 'setFormData', payload: e});
    return <>
        <Row className={'mb-3'}>
            <Col>
                <h5 className={'display-4'}>{t('bases.base.settings')}</h5>
            </Col>
        </Row>
        <Row>
            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <Col xs={12} md={8}>
                  <Label>{t('bases.base.registerWithoutInstructor')}</Label>
              </Col>
              <Col xs={12} md={2}>
                  <Switch value={state.is_coach_required} name={'is_coach_required'} onChange={setData}/>
              </Col>
              <div className='settings-divider'/>
            </ElementWithAccessInCountries>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.rentFullBoat')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.is_full_book_available} name={'is_full_book_available'}
                        onChange={e => {
                            setData(e)
                            state.is_full_book_available && dispatch({type: 'clearDiscountData', payload: 'full_book'})
                        }}/>
            </Col>
            <div className='settings-divider'/>

            <ElementWithAccessInCountries hideInCountries={['EN']}>
              <Col xs={12} md={8}>
                  <Label>{t('bases.base.instructorSelector')}</Label>
              </Col>
              <Col xs={12} md={2}>
                  <Switch value={state.is_coach_selectable} name={'is_coach_selectable'} onChange={setData}/>
              </Col>
              <div className='settings-divider'/>
            </ElementWithAccessInCountries>


            <Col xs={12} md={8}>
                <Label>{t('bases.base.subscriptionPay')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.promo_subscription_available} name={'promo_subscription_available'}
                        onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.onlyTeam')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.team_only} name={'team_only'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.onlyLink')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.is_private} name={'is_private'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            {state.id && <>
                <Col xs={12} md={8}>
                    <Label>{isSlot ? t('bases.base.slotClosed') : t('bases.base.eventClosed')}</Label>
                </Col>
                <Col xs={12} md={2}>
                    <Switch value={state.is_closed} name={'is_closed'} onChange={setData}/>
                </Col>
                <div className='settings-divider'/>
            </>}

            <Col xs={12} md={8}>
                <Label>{t('bases.base.checkAge')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.check_age_required} name={'check_age_required'} onChange={setData}/>
            </Col>
            {state.check_age_required ? <>
                    <Col sm={5} className={'mt-1 mb-3'}>
                        <Label>{t('bases.base.age')}</Label>
                        <Input type={state.check_age_value} name={'check_age_value'} value={state.check_age_value}
                                onChange={setData} placeholder={'16'}/>
                    </Col>  
                </> : <div className='settings-divider'/>
            }

            <Col xs={12} md={8}>
                  <Label>{t('bases.base.showBookingLimit')}</Label>
            </Col>
            <Col xs={12} md={2}>
                  <Switch value={state.show_booking_limit} name={'show_booking_limit'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>


            <Col xs={12} md={8}>
                <Label>{t('bases.base.hideUntil')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.hide_until_enabled} name={'hide_until_enabled'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.singleBooking')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.single_booking} name={'single_booking'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.disablePromo')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.disable_promo} name={'disable_promo'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
                <Label>{t('bases.base.paidLocally')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Switch value={state.paid_locally} name={'paid_locally'} onChange={setData}/>
            </Col>
            <div className='settings-divider'/>

            <Col xs={12} md={8}>
              <Label>{t('bases.base.tg_options')}</Label>
            </Col>
            <Col xs={12} md={2} className='mb-3'>
              <TelegramOptions tg_options={state.tg_options} onChange={setData} />
            </Col>
            {
                state?.hide_until_enabled &&
                <Col xs={12} md={8}>
                    <FormGroup>
                        <HideUntilField errors={errors} defaultValue={state?.hide_until_time || 0}
                                        control={control} setValue={setData}/>
                    </FormGroup>
                </Col>
            }

            <Col xs={12} md={8}>
                <Label>{t('bases.base.color')}</Label>
            </Col>
            <Col xs={12} md={2}>
                <Input bsSize={'sm'} type={'color'} value={state.calendar_color || '#000000'} name={'calendar_color'}
                       onChange={setData}/>
            </Col>
        </Row>
    </>
}

export default Settings;

Settings.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}