import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Col, Modal, ModalBody, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import PropTypes from 'prop-types';
import {DataTable, field} from "../../../generic/tables";
import { FaCheck, FaInfoCircle, FaPen, FaPlus, FaRegTimesCircle, FaTimes } from 'react-icons/fa';
import {AiOutlineSwap} from 'react-icons/ai'
import {Order} from "../../../administration";
import {useOrdersApi} from "../../../../lib/api/orders";
import {ModalConfirmation} from "../../../generic/modals";
import {ElementWithPermissions, MovePaymentModal} from "../index";
import './style.scss';
import {getArrivalSet, getSlotSet, getPracticeSet} from "./fieldSets";
import dayjs from "dayjs";
import {ExportFunButton} from "../../../generic/buttons";
import PayCertificatePopover from "../../popovers/PayCertificatePopover";
import {ConditionalField} from "../../../generic/fields";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import SubscriptionApplied from "./tableFieldElements/SubscriptionApplied";
import PaidWithRubles from "./tableFieldElements/PaidWithRubles";
import IsCanceled from "./tableFieldElements/IsCanceled";
import HasViolations from "./tableFieldElements/HasViolations";
import IsPartialCanceled from "./tableFieldElements/IsPartialCanceled";
import {t} from "i18next";
import {FaCopy} from "react-icons/all";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import IsAdded from './tableFieldElements/IsAdded';
import ProductPassportsComponent from './passports';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
import {RuleAccessProvider} from "../../../../lib/utils/frontRules";
import {frk} from "sv-common/constants/frontRoles";
import TinkoffIdField from "../TinkoffIdField";
import PayInstallmentPopover from "../../popovers/PayInstallmentPopover";

const chooseProductFieldSet = productType => {
    if (productType === 'slots')
        return getSlotSet;
    if (productType === 'arrivals')
        return getArrivalSet; 
    if (productType === 'practices')
        return getPracticeSet;

    return getSlotSet;
}

const ProductOrdersList = ({state, dispatch, product_type = 'slots', disabled, customFilters, additionalForProductFields}) => {
    const [selectedOrder, setSelectedOrder] = useState(false)

    const [isModalOpen, toggleModal] = useState(false);
    const triggerModal = () => {
        if (isModalOpen) setSelectedOrder(() => undefined)
        toggleModal(isModalOpen => !isModalOpen)
    }

    const [isModalConfirmationOpen, toggleModalConfirmation] = useState(false);
    const triggerModalConfirmation = () => {
        if (isModalConfirmationOpen) setSelectedOrder(() => undefined)
        toggleModalConfirmation(isModalOpen => !isModalOpen)
    }

    const [isMoveModalOpen, toggleMoveModal] = useState(false);
    const triggerMoveModal = () => {
        if (isMoveModalOpen) setSelectedOrder(() => undefined)
        toggleMoveModal(isModalOpen => !isModalOpen)
    }

    const {findAndCountEntries, setPaid, exportEntries} = useOrdersApi('orders');
    const exportButton = useCallback((dataParameters) => {
        if (product_type === PRODUCT_TYPES.ARRIVALS || product_type === PRODUCT_TYPES.PRACTICES
            || product_type === PRODUCT_TYPES.SLOTS || product_type === PRODUCT_TYPES.GROUPS) {
            // выводим только дочерние заказы, чтобы учитывать в выборке попутчиков
            return <ExportFunButton dataParameters={{
                ...dataParameters,
                filters: {...dataParameters.filters, master_order_null: product_type === PRODUCT_TYPES.SLOTS},
                productType: product_type
            }} exportFun={exportEntries}/>
        }
        return undefined;
    }, [product_type])

    const filters = {
        master_order_null: true,
        has_paid: true
    }

    let presetFilters = {}

    const addFilter = (filter) => (
        presetFilters = {
            ...presetFilters,
            ...filter
        }
    )

    if (product_type === 'slots') {
        filters.slot_id = state.id
        presetFilters = {
            base_id: state.event_id?.base_id,
            event_id: state.event_id?.id,
        }
    }
    if (product_type === 'arrivals' || product_type === 'practices') {
        filters.arrival_id = state.id
        presetFilters = {
            adventure_id: state.adventure_id?.id,
            arrival_id: state.arrival_id?.id,
        }
    }
    if (product_type === 'groups') {
        filters.group_id = state.id
    }

    if (selectedOrder?.group_id?.type === 'lecture')
        addFilter({type: 'lecture'});
    if (selectedOrder?.group_id?.type === 'theory')
        addFilter({type: 'theory'});
    if (selectedOrder?.product_type === 'practices')
        addFilter({type: 'practices'});

    const clickCb = (value) => {
        dispatch({
            type: 'setForm',
            payload: {
                reload: !state.reload,
                moved_orders_ids: value ? [...state.moved_orders_ids, value.id] : state.moved_orders_ids,
            }
        })
    }

    const isAddedField = () => {
        if (!additionalForProductFields?.instructorsArray) return [field(t('common.productOrderList.isAdded'), false, "added_to_manager_table", res => {
            return <IsAdded value={res.added_to_manager_table} id={res.id} />
          })];
        return [];
    }

    useEffect(() => {
        if (additionalForProductFields) {
            findAndCountEntries({...filters, ...customFilters})
            .then(additionalForProductFields?.onGetOrders)
        }
    }, [])

    return (
        <>
            {isMoveModalOpen &&
            <MovePaymentModal productType={product_type} selectedProductId={state.id} isModalOpen={isMoveModalOpen}
                              triggerModal={triggerMoveModal} orderId={selectedOrder?.id} clickCb={clickCb}
                              presetFilters={presetFilters} order={selectedOrder}
            />}
            {isModalOpen &&
            <Modal isOpen={isModalOpen} toggle={triggerModal} size={'lg'}>
                <ModalBody>
                    <Order product_url={state.url} product_type={product_type} isInline orderId={selectedOrder?.id}
                           onAnnulationCb={clickCb}
                           priceCurrency={state.event_id?.price_currency || state.price_currency} productId={state.id}
                           presetFilters={presetFilters}
                           onClickCb={(res) => {
                               triggerModal();
                               clickCb(res)
                           }}/>
                </ModalBody>
            </Modal>
            }
            {isModalConfirmationOpen &&
            <ModalConfirmation onClose={triggerModalConfirmation} isOpen={isModalConfirmationOpen}
                               onConfirm={() => setPaid(selectedOrder.id, !selectedOrder.has_paid).then(clickCb)
                                   .then(() => {
                                       triggerModalConfirmation();
                                       clickCb()
                                   })}
                               confirmButtonText={t('common.productOrderList.yes')}
                               text={t('common.productOrderList.areYouSure')}
                               title={t('common.productOrderList.deleteTitle')}
            />}
            <Row>
                {!additionalForProductFields?.instructorsArray && <><Col xs={10} md={3}>
                    <div className={'display-4'}>
                        {t('common.productOrderList.purchases')}
                    </div>
                </Col>
                <RuleAccessProvider accessKey={frk["SLOT-ITEM-ORDERS-CREATION"]}>
                    <Col className={'mb-3'}>
                        <ElementWithPermissions disabled={disabled}>
                            <button className={'btn btn-primary btn-rounded btn-sm ml-2'}
                                  onClick={triggerModal}>
                            <FaPlus size={'1em'}/>
                            </button>
                        </ElementWithPermissions>
                    </Col>
                </RuleAccessProvider></>}
                <Col xs={12}>
                    {(filters.slot_id || filters.arrival_id || filters.group_id) && <DataTable
                        findAndCount={findAndCountEntries}
                        filters={{...filters, ...customFilters}}
                        fields={[
                            ...isAddedField(),
                            ...chooseProductFieldSet(product_type)({...additionalForProductFields}),
                            field(<><AiOutlineSwap title={t('common.productOrderList.moved')}/></>, false, "is_moved", res => !!res.is_moved ? <FaCheck title={t('common.productOrderList.smallMoved')}/> : ''),
                            field(t('common.productOrderList.payment'), false, "paid_to_total",
                                res =>
                                    <span className="pay-percent" style={res.has_paid ? {color: 'green'} : {}}>
                                        {res.paid_to_total}%  <small>{res.paid_by_id?.first_name} {res.paid_by_id?.last_name}</small>
                                        {res.subscription_applied && <SubscriptionApplied res={res}/>}
                                        {res?.certificate_applied && <>
                                            <PayCertificatePopover orderId={res.id}
                                                               certificateName={res.certificate_applied.name}
                                                               certificateSize={res.certificate_applied.size_left}
                                                               certificatePriceCurrency={res.certificate_applied.price_currency}
                                                               certificateSum={res.certificate_sum}
                                            />
                                            &nbsp;
                                        </>}
                                        {res?.installment && <>
                                            <PayInstallmentPopover orderId={res.id}
                                                                   duration={res.installment.duration}
                                                                   label={res.installment.label}
                                                                   promocode={res.installment.promocode}
                                            />
                                            &nbsp;
                                        </>}
                                        <ConditionalField condition={res.invoice_page_url}>
                                            <CopyToClipboard
                                            text={`${t('common.productOrderList.yourSecondInvoiceLink')} "${state.name}" ${res.invoice_page_url}`}
                                            id={"invoicePageUrlCopy"}
                                            onCopy={() => toast(t('common.textCopied'), {type: 'success'})}>
                                                <button className="btn btn-link p-0 mb-1 ml-1" onSubmit={e => e?.preventDefault()}
                                                        onClick={e => e?.preventDefault()}><FaCopy/></button>
                                            </CopyToClipboard>
                                            <UncontrolledPopover placement="right" target={"invoicePageUrlCopy"} trigger={'hover'}>
                                                <PopoverBody>
                                                    {t('common.productOrderList.copySecondInvoiceLink')}
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                        </ConditionalField>
                                        <ConditionalField condition={res.is_partial_cancelled}>
                                            <IsPartialCanceled res={res}/>
                                        </ConditionalField>
                                        <ConditionalField condition={res.paid_with_rubles && res.payment_currency === 'EUR'}>
                                            <PaidWithRubles res={res}/>
                                        </ConditionalField>
                                        <ConditionalField condition={res.is_cancelled}>
                                            <IsCanceled res={res}/>
                                        </ConditionalField>
                                        <ConditionalField condition={res.client_id.has_violations}>
                                            <HasViolations res={res}/>
                                        </ConditionalField>
                                        <ConditionalField condition={res.paid_locally}>
                                            <div className={'mt-2'}><span className={'badge badge-warning'}>{t('common.productOrderList.paidLocally')}</span></div>
                                        </ConditionalField>
                                    </span>
                            ),
                            ...TinkoffIdField(),
                            field(t('common.productOrderList.promocode'), false, "applied_promocode",
                                res => res.promocode_applied ? res.promocode_applied.name : ''),

                            field(t('common.productOrderList.paymentTime'), false, "created_at", res => dayjs(res.created_at).format('MM/DD | HH:mm')),
                            field(<>
                              {t('common.productOrderList.mail_sent')}
                              <FaInfoCircle className='ml-1' id={"mail_sent_i"}/>
                              <UncontrolledPopover placement="right" target={"mail_sent_i"} trigger={'hover'}>
                                <PopoverBody>
                                  <p>{t('common.productOrderList.mail_sent_i_1')}</p>
                                  <p>{t('common.productOrderList.mail_sent_i_2')}</p>
                                  <p>{t('common.productOrderList.mail_sent_i_3')}</p>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </>,
                              false,
                              "mail_sent",
                              res => (
                                <>
                                  <div className='text-center'>
                                    {res.mail_sent ? <FaCheck color={'green'}/> : <FaTimes color={'red'}/>}
                                  </div>
                                </>
                              )
                            ),
                        ]}
                        sortField="created_at"
                        sortDir="desc"
                        additionalButtons={res => {
                            const editOrder = () => {
                                setSelectedOrder(() => res);
                                triggerModal();
                            }
                            const movePayment = () => {
                                setSelectedOrder(() => res);
                                triggerMoveModal();
                            }
                            const deleteOrder = () => {
                                setSelectedOrder(() => res);
                                triggerModalConfirmation()
                            }
                            return !disabled
                                ? <ButtonGroup>
                                    <RuleAccessProvider accessKey={frk["SLOT-ITEM-ORDERS-LIST-ROW-EDIT"]}>
                                        <Button color="success" size="xs" onClick={editOrder}>
                                            <FaPen/>
                                        </Button>
                                    </RuleAccessProvider>
                                    <RuleAccessProvider accessKey={frk["SLOT-ITEM-ORDERS-LIST-ROW-TRANSFER"]}>
                                        <Button size="xs" color="info" onClick={movePayment}>
                                            <AiOutlineSwap/>
                                        </Button>
                                    </RuleAccessProvider>
                                    <RuleAccessProvider accessKey={frk["SLOT-ITEM-ORDERS-LIST-ROW-REMOVE"]}>
                                        <Button color="danger" size="xs" onClick={deleteOrder}>
                                            <FaRegTimesCircle/>
                                        </Button>
                                    </RuleAccessProvider>
                            </ButtonGroup>
                                : null
                        }}
                        name={product_type + "_orders-list"}
                        reload={state.reload}
                        rowClass={res => {
                            if (res.paid_for_ids?.length > 1)
                                return ''
                        }}
                        tableButtons={!additionalForProductFields?.instructorsArray ? exportButton : null}
                    />}
                    <ElementWithAccessInCountries hideInCountries={['RU']}>
                      <ProductPassportsComponent productId={state.id} type={product_type} fileName={`${state.name}_${state.start_date}${state.start_time ? `_${state.start_time}` : ''}`} />
                    </ElementWithAccessInCountries>
                </Col>
            </Row>
        </>

    )
}

export default ProductOrdersList;

ProductOrdersList.propTypes = {
    state: PropTypes.object.isRequired,
    product_type: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    customFilters: PropTypes.object,
    additionalForProductFields: PropTypes.object,
}