import "./index.scss";
import Select from "react-select";
import React, {useState} from "react";
import LinksHistory from "../LinksHistory";
import {CopyLink} from "../../../generic/buttons";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useLinksApi} from "../../../../lib/api/links";
import {toast} from "react-toastify";
import {ElementWithPermissions} from "../../../common/formElements";
import {ENTITIES, useRoleSystem} from "../../../../lib/utils/hooks/useRoleSystem";
import {getCityOptions} from "../../../school/theory/EditModalChildren";
import {t} from "i18next";
import _ from "lodash";


const TheoryLinksGenerator = () => {
    const FORMATS = [{value: 'online', label: t('management.linksGenerator.online')}, {value: 'offline', label: t('management.linksGenerator.offline')}];
    const TYPES = [{value: 'regular', label: t('management.linksGenerator.regular')}, {value: 'intensive', label: t('management.linksGenerator.intensive')}];
    const CATEGORIES = [
        {value: 'school', label: 'Школа'},
        {value: 'gims', label: 'Гимс'},
        {value: '', label: 'Все'}
    ];

    const [selectedFormat, setSelectedFormat] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [reload, setReload] = useState(false);
    const [generatedLink, setGeneratedLink] = useState("");
    const permissions = useRoleSystem(ENTITIES.LINK_GEN);
    const linksEndpoints = useLinksApi();

    const generateLink = async () => {
        if ((!selectedFormat?.length && !selectedCities?.length && !selectedTypes?.length) || _.isEmpty(selectedCategory)) {
            toast.error(t('management.linksGenerator.enterField'))
            return;
        }
        const url = await linksEndpoints.generateLinkForTheory(
            selectedFormat?.value || '',
            selectedCities?.length && !isOnline? selectedCities.map(c => c.value) : [],
            selectedTypes?.length ? selectedTypes.map(t => t.value) : [],
            selectedCategory.value || '',

        )
        setGeneratedLink(url);
        setReload(!reload);
    };

    const isOnline = selectedFormat.value === 'online';
    return <>
        <form>
            <Row>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.eventFormat')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'format'} isMulti={false} menuPlacement={'bottom'} value={selectedFormat}
                                placeholder={t('management.linksGenerator.choose')} onChange={setSelectedFormat}
                                options={FORMATS.map(format => ({label: format.label, value: format.value}))}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.city')}<span style={{color: 'red', visibility: isOnline ? 'hidden' : 'visible'}} >*</span></Label>
                        <Select name={'cities'} isMulti menuPlacement={'bottom'} value={isOnline ? [] : selectedCities} isDisabled={isOnline}
                                options={getCityOptions()
                                    .filter(city => city.props.id !== 'online')
                                    .map(city => ({label: city.props.value, value: city.props.value}))}
                                onChange={setSelectedCities}
                                placeholder={isOnline ? t('management.linksGenerator.notAvailableOnline') : t('management.linksGenerator.choose')}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.activityType')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'type'} isMulti menuPlacement={'bottom'} value={selectedTypes}
                                options={TYPES.map(type => ({label: type.label, value: type.value}))}
                                onChange={setSelectedTypes} placeholder={t('management.linksGenerator.choose')}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <FormGroup>
                        <Label>{t('management.linksGenerator.product')}<span style={{color: 'red'}}>*</span></Label>
                        <Select name={'category'} menuPlacement={'bottom'} value={selectedCategory} isMulti={false}
                                options={CATEGORIES.map(category => ({label: category.label, value: category.value}))}
                                onChange={setSelectedCategory} placeholder={t('management.linksGenerator.choose')}/>
                    </FormGroup>
                </Col>
                <Col lg={4} className="mb-3">
                    <Label>{t('management.linksGenerator.link')}</Label>
                    <Input name="link" value={generatedLink} disabled
                           placeholder="https://booking.silavetra.com/sport?base=berezki&event=train"/>
                    <div className="mt-2">
                        <CopyLink href={generatedLink} text={t('management.linksGenerator.followLink')}
                                  className={!generatedLink ? 'disabledCopyButton' : ''}
                                  buttonClassName={!generatedLink ? 'disabledCopyButton' : ''}/>
                    </div>
                </Col>
                <Col xs={12} className="d-flex align-items-center">
                    <ElementWithPermissions disabled={!permissions.edit}>
                        <Button className="btn-rounded" size="sm" color="primary" onClick={generateLink}>{t('management.linksGenerator.generate')}</Button>
                    </ElementWithPermissions>
                </Col>
            </Row>
        </form>
        <LinksHistory reload={reload}/>
    </>
}

export default TheoryLinksGenerator;