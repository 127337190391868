export const en = {
    directories: {
        subscriptions: {
            name: "Name",
            country: "Country",
            price: "Price",
            active: "Active",
            commonDiscount: "Common discount",
            individualDiscount: "Individual discount",
            indivDiscount: "Indiv. discount",
            raceDiscount: "Cup discount",
            sailGPDiscount: "Sail GP discount",
            editModalChildren: {
                nameInputLabel: "Name",
                nameInputPlaceholder: "Subscription 3000",
                keyInputLabel: "Key",
                keyInputValidation: "Invalid symbols only: latin, numbers, '_' and '-'",
                raceDiscount: "Cup & Races for exp. discount",
                sailGPDiscount: "Sail GP discount",
            }
        },
        directoryTemplate: {
            create: "Create",
            search: {
                coordinator: 'Search by coordinator',
                base_addresses: 'Search by base address',
                areas: 'Search by areas',
                formats: 'Search by formats',
            }
        },
        breadcrumbs: {
            subscriptions: "Subscriptions",
            directories: "Directories",
            difficultyLevels: "Difficulty levels",
            userFields: "User fields",
            userFieldsTemplates: "Fields templates",
            travelOptions: "Travel options",
            currencies: "Currencies",
            boatsClasses: "Boats classes",
            shirtSizes: "Shirt sizes",
            publicContracts: "Public contracts",
            extraMaterials: "Extra materials",
            legalEntities: "Legal entities",
            checks: "Checks",
            corpClients: "Corp. clients",
            transactionStatus: "Transaction status",
            coordinators: "Coordinators",
            baseAddresses: "baseAddresses",
        },
        difficultyLevels: {
            level: "Level",
            briefly: "Briefly",
            description: "Description",
            group: "Group",
            descriptionPlaceholder: "Description...",
            difficultyLevel: "Difficulty level",
            maxLevel: "Max level",
            chooseTag: "Choose tag",
        },
        userFields: {
            fieldName: "Field name",
            required: "Is required",
            optionalToShow: "Optional to show",
            nameEn: "Name (en)",
            nameEnHint: "without spaces, through _",
            nameRu: "Name (ru)",
            nameRuPlaceholder: "Example",
            requiredLabel: "Field is required",
            optionalToShowLabel: "Optional to show label",
        },
        userFieldsTemplates: {
            templateName: "Template name",
            name: "Name",
            includedFields: "Included fields",
            includedFieldsPlaceholder: "Choose options",
        },
        currencies: {
            name: "Name",
            symbol: "Symbol"
        },
        boatsClasses: {
            boatClass: "Boat class",
            name: "Name",
            boatPlaces: "Boat places",
            isActive: "Is active",
        },
        shirtSizes: {
            sizeNumbers: "Size (numbers)",
            sizeLetters: "Size (letters)",
        },
        publicContracts: {
            link: "Link",
            name: "Name",
            namePlaceholder: "Payment rules."
        },
        legalEntities: {
            name: " Name",
            shopId: "Payment shop ID",
            apiKey: "Payment system API key",
            subscriptionAvailable: "Subscription available",
            blrApplicable: "BLR applicable",
            certificateType: "Certificate type",
            paymentSystem: "Payment system",
            namePlaceholder: "Pinigin",
            shopIdLabel: "shop ID",
            shopIdHint: "Can write APPROVED for DEMO terminals",
            apiKeyLabel: "API key",
            orangeData: "Data for OrangeData",
            inn: "INN",
            privateKey: "Private key",
            certificate: "Certificate",
            certificateKey: "Certificate key",
            chooseType: "Choose type",
            insuranceId: "Id Pulse",
            insuranceIdPlaceholder: "Add Id Pulse",
            installment: {
                label: 'Installments',
                types: 'Installment types',
                promocode: 'Promocode',
                markup: 'Mark Up (%)',
                duration: 'Duration (Months)',
                apiKey: 'Showcase ID',
                shopId: 'Shop ID',
                passId: 'Pass ID',
            }
        },
        checks: {
            name: "Name",
            type: "Check type",
            namePlaceholder: "Greek vacation",
            chooseType: "Choose type",
            varToUse: "You can use variable:",
            baseVarInfo: " - in the check will be an event base ",
            yachtVarInfo: " - in the check will be a boat ",
            example: "Example: Train on ",
            exampleRes: "In the check will be: Train on ",
        },
        transactionStatus: {
          title: 'Title',
          type: 'Type'
        }
    },
    school: {
        theory: {
            groupsList: {
                deleteGroup: "Delete group",
                exactlyDeleteGroup: "Delete group exactly?",
                noDeleteGroup: "No",
                yesDeleteGroup: "Yes",
                newGroupTheory: "New group (theory)",
                captainSchool: "Captain school",
                gims: "Gims",
                moscow: "Moscow",
                products: "Products:",
                recruiting: "Recruiting",
                learning: "Learning",
                classes: "Classes:",
                all: "All",
                closest: "Closest",
                archive: "Archive",
                group: "Group",
                groupSettings: "Group settings",
                copyGroup: "Copy group",
                place: "Place",
                lessonsPerWeek: "Lessons per week",
                customSchedule: "Custom schedule",
                intensive: "intensive",
                lec: "lec.",
                nextLesson: "Next lesson",
                teacher: "Teacher",
                noPicked: "No picked",
                bookedToTotal: "Booked to total",
                newGroup: "New group",
                groups: 'Groups:',
            },
            tabList: {
                aboutArrival: "About arrival",
                clients: "Clients",
                globalSales: "Sale",
            },
            mainInfo: {
                brieflyAboutGroup: "Briefly about group",
                lessonsPerWeek: "less. per week"
            },
            lessonsTable: {
                lessons: "Lessons",
                add: "Add",
                date: "Date",
                start_time: "Start time",
                theme: "Lesson theme",
                deleteLesson: "Delete lesson",
                youDeleteLesson: "You're deleting lesson:",
                isEndTimeCheck: "Close event registration by end date?",
            },
            editModalChildren: {
                lessonStart: "Lesson start",
                lessonEnd: "Lesson end",
                lessonNumber: "Lesson number",
                meeting: "Meeting",
                product: "Product:",
                school: "School",
                gims: "Gims",
                intensive: "Intensive",
                moscow: "Moscow",
                online: "Online",
                address: "Address",
                totalSits: "Total sits",
                schedule: "Schedule",
                startDate: 'Start date',
                pickTeacher: 'Pick teacher',
                pickTeachers: 'Pick extra teachers',
                tgLink: 'Telegram channel',
                platform: 'Platform',
                platformCode: 'Platform code',
                promocodesActive: 'Promocodes active',
                checkAge: 'Check age',
                age: 'Age',
                quizLink: 'Quiz link',
                calendarUrl: 'Calendar url',
                extraMaterials: 'Extra materials',
                place: 'Place',
                price: 'Price',
                additionalInfo: 'Additional info',
                paidLocally: 'Paid locally',
                tg_options: 'Telegram',
                isInstallment: 'Enable installments',
            },
            edit: {
                travelName: "Travel name",
                save: "Save",
                cancel: "Cancel",
                groupMain: "Group info",
            },
            techInfo: {
                placeAmount: "Place amount",
                price: "Price",
                groupURL: "Group URL",
                telegramLink: "Telegram channel link",
                platformLink: "Platform link",
                platformCode: "Platform code",
                quizLink: "Quiz link",
                calendarLink: "Calendar link",
            },
            viewTeacherModal: {
                pickTeacher: "Pick teacher",
                pickTeachers: 'Pick extra teachers',
                close: "Close",
            },
            addressBlock: {
                pickTeacher: "Pick teacher",
                pickTeachers: 'Pick extra teachers',
                place: "Place",
                moscow: "Moscow",
                online: "Online",
                address: "Address",
                coordinates: "Coordinates",
            },
            switchBlock: {
                isOpen: "Is open",
                isPrivate: "Only link available",
                isInstallment: 'Enable installments',
                ageRequired: "Age required",
                age: "Age",
            },
            additionalInfo: {
                additionalLinks: "Links on additional materials"
            },
            cities: {
              online: "Online",
              moscow: "Moscow",
              saintPetersburg: "Saint-Petersburg",
              minsk: "Minsk",
              nizhniyNovgorod: "Nizhniy Novgorod",
              turkey: "Turkey",
              portRashid: "Port Rashid, Dubai",
              sochi: "Sochi",
              yaroslavl: "Yaroslavl",
              kazan: "Kazan",
              vladivostok: "Vladivostok",
            },
        },
        practices: {
            gridType: 'Grid type:',
            events: 'Events',
            arrivals: 'Arrivals',
            classes: 'Classes:',
            closest: 'Closest',
            all: 'All',
            group: 'Group',
            arrivalSettings: 'Arrival settings',
            country: 'Country',
            dates: 'Dates',
            bookedToTotal: 'Booked to total',
        },
        breadcrumbsAndTitle: {
            theory: 'Theory',
            lecture: 'Lecture',
            practice: 'Practice',
            creating: 'creating',
            editing: 'editing',
        }
    },
    adventures: {
        adventuresList: {
            deleteTravel: 'Delete travel',
            areYouSure: 'Are you sure?',
            sure: 'Sure!',
            current: 'Current',
            past: 'Past',
            archive: 'Archive',
            all: 'All',
            russia: 'Russia',
            gastroRegata: 'Gastro Regatta',
            date: 'Date',
            load: 'Load',
            world: 'World',
        },
        adventureCard: {
            copyAdventure: 'Copy adventure',
            editAdventure: 'Edit adventure',
            deleteAdventure: 'Delete adventure',
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
        arrival: {
            title: 'Arrival:',
            updateSuccess: 'Success update!',
            somethingWentWrong: 'Something went wrong!',
            dataWasSync: 'Data was sync',
            arrivalName: 'Arrival name',
            save: 'Save',
            addRelated: 'Add related',
            no: 'No',
            relatedArrival: 'Related arrival:',
            left: 'left',
            load: 'load',
            about: 'About arrival',
            dates: 'Dates',
            reserved: 'reserved'
        },
        tabList: {
            clients: "Clients",
            aboutArrival: "About arrival",
            promocodes: "Promocodes",
            globalSales: "Sale",
        },
        mainInfo: {
            brieflyAbout: 'Briefly about',
            practice: 'practice',
            travel: 'travel',
            basePrice: 'Base price:',
        },
        arrivalsTable: {
            thereIsPayed: 'There is payed places Are you sure?',
            areYouSure: 'Are you sure?',
            arrivalDeleting: 'Arrival deleting',
            no: 'No',
            yes: 'Yes',
            arrivals: 'Arrivals',
            add: 'Add',
            hideExpired: 'Hide expired',
            arrival: 'Arrival',
            name: 'Name',
            price: 'Price',
            bookedToTotal: 'Booked to total',
            link: 'Link',
        },
        editArrival: {
            name: 'Name',
            date: 'Date',
            repeat: 'Repeat',
            pickRepeat: 'Pick repeat',
            once: 'Once',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
        },
        edit: {
            name: 'Name',
            practice: 'of practice',
            travel: 'travels',
            save: 'Save',
            cancel: 'Cancel',
            main: 'Main',
            legalEntity: 'Legal entity',
            pickLegalEntity: 'Pick legal entity',
            totalSits: 'Total sits',
            price: 'Price',
            urlHint: 'Sign \'/\' unacceptable',
            arrival: 'arrival',
            partialPayments: 'Partial payments',
            coordinates: 'Coordinates',
            legalEntities: 'Legal entities',
            pickContract: 'Pick contract',
            paymentDesc: 'Payment description (en)',
            paymentHint: 'Max length 100 signs. Only for RBK',
            pickCheck: 'Pick check',
            check: 'Cashier check',
            open: 'Open',
            private: 'Private',
            checkAge: 'Check age',
            age: 'Age',
            currencyOnly: 'Currency only',
            showRestrictions: 'Show restrictions',
            loyalDiscount: 'Loyal discount',
            hasTransfer: 'Has transfer',
            pickPrice: 'Pick price',
            currency: 'Currency',
            teamBookDiscount: 'Team book discount',
            sum: 'Sum',
            percent: 'Percent',
            peopleCount: 'People count',
            type: 'Type',
            askAlways: 'Ask always',
            field: 'Field',
            show: 'Show',
            whatWeNeed: 'What we need from client',
            template: 'Template',
            pickTemplate: 'Pick template',
            arrivalCard: 'Arrival card',
            travelCard: 'Travel card',
            imgSize: 'Cover (900х380 px)',
            fileSize: 'File size no more 3 mb',
            difficulty: 'Difficulty',
            pickDifficulty: 'Pick difficulty',
            averageTime: 'Average time for day',
            timeOnWaterFrom: 'Time on the water FROM',
            timeOnWaterTo: 'Time on the water TO',
            airTemperature: 't° air',
            waterTemperature: 't° water',
            arrivalInfo: 'Arrival info',
            whatInclude: 'What\'s included',
            whatIncludePlaceholder: 'hammock\nslippers',
            whatNotInclude: 'What isn\'t included',
            whatNotIncludePlaceholder: 'flight\nresidence',
            restrictions: 'Restrictions',
            restrictionsPlaceholder: 'If there are restrictions for traveling, describe them here',
            insideInfo: 'Inside info',
            arrivalComment: 'Arrival comment',
            travelComment: 'Travel comment',
            commentHint: 'One-two small size strings, 120 symbols',
            arrivalClientComment: 'Arrival comment for clients',
            travelClientComment: 'Travel comment for clients',
            checkInfo: 'Check info',
            agentSign: 'Agent sign',
            pickAgentSign: 'Pick the agent sign',
            another: 'Another',
            supplierName: 'Supplier name',
            supplierPhone: 'Supplier phone',
            supplierPhoneHint: 'In +70001112233 format',
            supplierINN: 'Supplier INN',
            supplierINNHint: 'Only numbers, without separators',
            additionalLinks: "Additional links",
            tg_options: 'Telegram',
        }
    },
    bases: {
        events: {
            newEventBlocks: {
                promocodes: {
                    title: 'Promocodes',
                    existing: 'Existing',
                    promocodesFor: 'Promocodes for',
                    active: 'active',
                    new: 'New',
                    name: 'Name',
                    size: 'Size',
                    notSpecified: 'Not specified',
                    expiresAt: 'Expires at',
                    before: 'Before',
                    indefinitely: 'indef.',
                    bases: 'bases',
                    practices: 'practices',
                    slots: 'slots',
                    events: 'events',
                    travels: 'travels',
                    practiceArrival: 'practice arrival',
                    arrival: 'arrival',
                    groups: 'group',
                    camps: 'camps'
                },
                pickEventType: 'Pick event type',
            },
            newEvent: 'New event',
            corpClient: 'Corp. client',
        },
        base: {
            reloadSuccess: 'Success reload!',
            somethingWentWrong: 'Something went wrong!',
            requiredError: 'All required fields not filled!',
            baseName: 'Base name',
            save: 'Save',
            cancel: 'Cancel',
            about: 'About',
            instructors: 'Instructors',
            administration: 'Administration',
            baseSearch: 'Base search',
            create: 'Create',
            name: 'Name',
            phone: 'Phone',
            link: 'Link',
            country: 'Country',
            city: 'City',
            order: 'Order',
            open: 'Open',
            boats: 'Boats',
            deleteClass: 'Delete yacht class?',
            deleteClassText: 'If the boat is broke down, for analytic will be better decrease boats amount',
            no: 'No',
            yes: 'Yes',
            attachedBoats: 'Attached boats',
            boatClass: 'Boat class',
            availableToday: 'Available today',
            delete: 'Delete',
            boatClassPlaceholder: 'class >9000',
            pickClass: 'Pick class',
            amount: 'Amount',
            linkHowToGet: 'Link "how to get',
            hideToTime: 'Hide to time',
            paidLocally: 'Paid locally',
            cover: 'Cover (900х380 px)',
            fileSizeMax: 'Filesize not more 3 mb',
            street: 'Street',
            streetEx: 'Pushkina',
            house: 'House',
            index: 'Index',
            coords: 'Coordinates',
            addInfo: 'Add. info',
            firstPier: '1 pier',
            deleteBase: 'Delete base?',
            deleteBaseInfo: 'Base deleting will result to unarchive it - it will hide from the available list',
            main: 'Main',
            namePlaceholder: 'Strogino',
            nameLabel: 'Name displayed in the administration and to the user',
            baseUrl: 'base URL',
            baseUrlPlaceholder: 'URL displayed in the administration and to the user; are not allowed "/" and "_" symbols',
            invalidSymbol: 'Invalid symbol',
            baseOrder: 'Base order',
            baseOrderPlaceholder: 'Order will affect on the administration and user issuance',
            bookAddress: 'Booking address',
            bookAddressHint: 'Default value - ',
            baseWork: 'Base work',
            openedSeason: 'Season is opened',
            deleteBaseButton: 'Big button to delete the base',
            deleteBaseText: 'Delete base',
            archiveBase: 'Base and all order history will go to the archive',
            cantCancel: 'You can\'t cancel this action',
            events: 'Events',
            slots: 'Slots',
            showHidden: 'Show hidden',
            dateFilter: 'Date filter',
            practiceSv20: 'Practice SV-20',
            date: 'Date',
            statusReloaded: 'Status reloaded',
            slotDeleting: 'Slot deleting',
            areYouSureSlot: 'Are you sure you want to delete slot ?',
            time: 'Time',
            orders: 'Total booked',
            eventDeleting: 'Event deleting',
            areYouSureEvent: 'Are you sure you want to delete event ?',
            createEvent: 'Create event',
            comment: 'Comment',
            duration: 'Duration',
            brieflyAboutEvent: 'Briefly about event',
            go: 'go...',
            hideExpired: 'Hide expired',
            add: 'Add',
            haveNoTrainer: 'Have no trainer',
            showPromo: 'Show promocodes',
            startDate: 'Start date',
            endDate: 'End date',
            repetitionRate: 'Repetition rate',
            pickRepetitionRate: 'Pick repetition rate',
            once: 'Once',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
            pickInstructors: 'Pick instructors',
            general: 'General',
            eventType: 'Event type',
            luch: 'Luch',
            pickLuch: ' (Pick luch)',
            pickLaser: ' (Pick laser)',
            boatLimit: 'Boat limit',
            price: 'Price',
            settings: 'Settings',
            registerWithoutInstructor: 'Register without instructor',
            rentFullBoat: 'Full boat rent',
            instructorSelector: 'Instructor selector',
            subscriptionPay: 'Subscription pay',
            onlyTeam: 'Team only',
            onlyLink: 'Only link access',
            slotClosed: 'Slot closed',
            eventClosed: 'Event closed',
            checkAge: 'Check age',
            hideUntil: 'Hide until',
            age: 'Age',
            color: 'Event color in a calendar',
            sum: 'Sum',
            percent: 'Percent',
            discountText: 'Discount text',
            discountTextPlaceholder: 'Important comment',
            discounts: 'Discounts',
            coachlessDisc: 'Coachless discount',
            fullbookDisc: 'Fullbook discount',
            singleBoat: 'Discount isn\'t apply to single boats',
            fullbookUnavailable: 'Fullbook unavailable',
            teamDisc: 'Team discount',
            type: 'Type',
            infoAndLinks: 'Info and links',
            eventComment: 'Event comment',
            eventCommentHint: 'One-two small size strings, 60 symbols',
            eventUrl: 'Event URL',
            eventUrlHint: 'Symbol \'/\' unavailable',
            decs: 'Description',
            decsHint: 'Not more 2 strings, 250 symbols',
            decsPlaceholder: 'Description example',
            bringGuide: 'What bring to the practice',
            publicContract: 'Public contract',
            pickContract: 'Pick contract',
            returnPolicy: 'Return policy',
            deleteEvent: 'Delete event',
            deleteEventInfo: 'Event deleting won\'t touch created slots',
            extraMaterials: 'Extra materials links',
            slotComment: 'Slot comment',
            slotStatus: 'Slot status',
            coming: 'Coming',
            postponed: 'Postponed',
            canceled: 'Canceled',
            finished: 'Finished',
            other: 'Other',
            slotPrice: 'Slot price',
            sick: 'Sick',
            busy: 'Busy',
            inactive: 'Inactive',
            active: 'Active',
            instructor: 'Instructor',
            status: 'Status',
            yachtsClasses: 'Yacht classes',
            goToSlot: 'Go to the slot',
            attachInstructor: 'Instructor is in other base. Will attach him?',
            instructorBusy: ' Instructor is busy in the other order or slot: ',
            instructorBusyOtherSlot: 'Instructor is is this base but other slot. Will attach him?',
            instructorStatus: 'Instructor status',
            attach: 'Attach',
            pickInstructor: 'Pick instructor',
            close: 'Close',
            URLCopied: 'URL was copied',
            syncData: 'Sync data',
            loading: 'loading',
            remained: 'remained',
            users: 'Users',
            promocodes: 'Promocodes',
            slot: 'Slot:',
            durationPlaceholder: '2h 30m',
            slotLowercase: 'slot',
            singleBooking: 'Single booking',
            disablePromo: 'Disable promocodes and certificates',
            reserved: 'reserved',
            tg_options: 'Telegram',
            shot_in_admin_tooltip: 'If the button is active, then the base is not displayed in the general list of bases; if the button is inactive, then the base is displayed in the general list of bases',
            deleteInstructor: 'Delete instructor?',
            confirm: 'Confirm',
            showBookingLimit: 'Show booking limit',
            instructorIsBooked: 'The instructor is booked. To remove an instructor from a slot, instructors must be swapped in the orders below.',
        },
        breadcrumbsAndTitle: {
            bases: 'Bases',
            calendar: 'Calendar',
            events: 'Events',
            editing: 'Editing',
            new: 'New',
            newBase: 'New base',
        }
    },
    globalSettings: {
        breadcrumbs: {
            global_settings: 'Global settings',
            separate_functionality: 'Separate functionality'
        },
        separateFunctionality: {
            enableCustomerInsurance: 'Enable customer insurance',
            enablePartialPayment: 'Enable partial payment',
            enableInstallment: 'Enable installment'
        }
    },
    generic: {
        title: "Main menu",
        pager: {
            entriesPerPage: "Entries per page",
            of: "of"
        },
        cancelButton: "Cancel",
        exportButton: {
            exportMaster: 'only master orders',
            exportWithCompanion: 'orders with companions',
            export: "Export",
            allTable: "All table",
            currentPage: "Current page",
            loading: "Loading...",
            ok: "Ok",
            uploadFile: "Upload file",
            downloadLater: "Unloading takes more time. You can download the file later in the downloads section",
            downloadLaterShort: "You can download it later in the downloads section",
            discharge: "Discharge",
            exportByBase: "Export all orders by base",
            exportByBaseTooltip: "Export orders from all slots in every base events",
            exportByAdventureDirection: "Travel",
            exportByGroupDirection: "School",
            exportByEventDirection: "Sport",
            exportOrdersByDirection: "Export orders"
        },
        canselButton: "Cansel",
        okButton: "Ok",
        modals: {
            ModalConfirmation: {
                cancel: "Cancel"
            }
        },
        datePicker: {
            date: 'Date'
        },
        editModal: {
            edit: 'Edit',
            create: 'Create'
        },
        dropzone: {
            uploadFile: 'Upload file',
            delete: 'Delete',
            dropFile: 'Drop file (or click)',
            formatTooLarge: 'Format too large',
            invalidType: 'Invalid file type',
        },
        rangedSelectionDatePicker: {
            placeholder: 'Pick start and end date',
        }
    },
    common: {
        yesSure: 'Yes',
        cancel: 'Cancel',
        textCopied: 'Text copied',
        create: 'Create',
        success: "Success!",
        save: "Save",
        resume: 'Resume',
        date: 'Date',
        forms: {
            priceCurrency: {
                currency: "Currency",
                pickCurrency: "Pick currency"
            },
            legalEntity: {
                label: "Legal entity",
                pickLegalEntity: "Pick legal entity",
            },
            alter_legalEntity: {
                label: "Alt legal entity",
                pickLegalEntity: "Pick alt legal entity",
                tooltip: 'Specify a legal entity that will accept money for training without an instructor/rental. This is necessary to comply with the law.'
            },
            timeZonePicker: {
                label: 'Time zone'
            },
            publicContracts: {
                label: 'Public contracts',
                pickContract: 'Pick contract'
            },
            alter_publicContracts: {
                label: 'Public alt contracts',
                pickContract: 'Pick alt contract',
                tooltip: 'Indicate the offer that the user will agree to when purchasing a lesson without an instructor/renting. This is necessary to comply with the law.'
            },
            city: {
                label: 'City/start place',
                placeholder: 'Moscow'
            },
            country: {
                label: 'Country',
                placeholder: 'Russia',
                pickCurrency: "Pick currency",
                pickCountry: "Pick country"
            },
            movePaymentModalGroups: {
                areYouSureClient: 'Are you sure you want move the client?',
                areYouSureOrder: 'Are you sure you want move the entire order?',
                areYouSurePastClient: 'You are trying to transfer a client to a past slot, transfer anyway?',
                areYouSurePastOrder: 'You are trying to transfer an order to a past slot, transfer anyway?',
                areYouSureClosedClient: 'You are trying to transfer a client to a closed event, transfer anyway?',
                areYouSureClosedOrder: 'You are trying to transfer an order to a closed event, transfer anyway?',
                moving: 'Moving',
                pickDates: 'Pick dates',
                yes: 'Yes',
                clientMoving: 'Client moving',
                name: 'Name',
                startDate: 'Start date',
                endDate: 'End date',
                bookedToTotal: 'Booked to total',
                pick: 'Pick',
            },
            phone: {
                label: 'Phone',
                minMessage: 'Check phone length',
            },
            hideUntil: {
                label: 'Hide until'
            },
            countryList: {
                label: 'Country'
            },
            movePaymentModal: {
                successUpdate: 'Success update!',
                yes: 'Yes',
                areYouSureClient: 'Are you sure you want to move client?',
                areYouSureOrder: 'Are you sure you want to move whole order?',
                areYouSurePastClient: 'You are trying to transfer a client to a past event, transfer anyway?',
                areYouSurePastOrder: 'You are trying to transfer an order to a past event, transfer anyway?',
                areYouSureClosedClient: 'You are trying to transfer a client to a closed event, transfer anyway?',
                areYouSureClosedOrder: 'You are trying to transfer an order to a closed event, transfer anyway?',
                clientMoving: 'Client moving',
                move: 'Moving',
                pickBase: 'Pick base',
                pickEvent: 'Pick event',
                pickDates: 'Pick dates',
                name: 'Name',
                date: 'Date',
                time: 'Time',
                reservedSits: 'Booked sits',
                bookedToTotal: 'Reserved sits',
                pick: 'Pick',
                startDate: 'Start date',
                endDate: 'End date',
                pickTravel: 'Pick travel',
                paymentMade: 'Paid orders',
            },
            maxLength: 'More than {{count}} char.'
        },
        extraMaterialsModal: {
            pickExtraMaterials: 'Pick extra materials',
            close: 'Close',
        },
        extraMaterialsTable: {
            name: 'Name',
            link: 'Link',
            delete: 'Delete',
        },
        productOrderList: {
            yes: 'Yes',
            areYouSure: 'Are you sure, you want to cancel the entire order?',
            deleteTitle: 'Cancelling entire order',
            purchases: 'Purchases',
            movedAndCancelledPurchases: 'Cancelled and moved purchases',
            moved: 'Moved',
            smallMoved: 'moved',
            payment: 'Payment',
            discount: 'discount',
            common: 'common',
            individual: 'individual',
            bankId: 'Tinkoff client ID',
            paidLocally: 'Paid locally',
            promocode: 'Promocode',
            paymentTime: 'Payment time',
            name: 'Name',
            lastname: 'Lastname',
            phone: 'Phone',
            count: 'Count',
            comment: 'Comment',
            sailingExperience: 'Sailing experience',
            email: 'Email',
            withoutInstructor: 'Without instructor',
            anyInstructor: 'Any instructor',
            experience: 'Experience',
            instructor: 'Instructor',
            copySecondInvoiceLink: 'Copy second payment link',
            yourSecondInvoiceLink: 'Your link to the payment of the second payment',
            isAdded: 'Recorded',
            level: {
              beginner: 'Beginner',
              experienced: 'Experienced',
              advanced: 'Advanced',
            },
            mail_sent: 'Mail sent',
            mail_sent_i_1: '1. If all users have a red cross, check if there is a letter template to Dashly',
            mail_sent_i_2: '2. If the user has a green checkmark, the letter has been sent to the client',
            mail_sent_i_3: '3. If the user displays a cross within 24 hours after placing the order, the email was not sent to the user.',
        },
        popovers: {
            remainder: 'Remainder:',
            writeOffAmount: 'Write-off amount:',
            subscriptionNameCopied: 'Subscription name has been copied',
            installmentNameCopied: 'Installment code has been copied',
            installmentDuration: 'Duration (months)',
            installmentPromocode: 'Installment code',
        },
        timeZone: {
            pickZone: 'Pick timezone',
            kaliningrad: '+2 Kaliningrad',
            moscow: '+3 Moscow / Minsk',
            samara: '+4 Samara',
            ekaterinburg: '+5 Ekaterinburg',
            omsk: '+6 Omsk',
            krasnoyarsk: '+7 Krasnoyarsk',
            irkutsk: '+8 Irkutsk',
            yakutsk: '+9 Yakutsk',
            vladivostok: '10 Vladivostok',
            magadan: '+11 Magadan',
            kamchatka: '+12 Kamchatka',
            dubai: '+4 Dubai',
        },
        totalSitsDecrease: 'When the number of places decreases, the load will be recalculated',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        areYouSure: 'Are you sure you want to delete this?',
        deleting: 'Deleting',
        comment: 'Comment',
        edit: 'Edit',
        showAll: 'Show all',
        hide: 'Hide',
        fullName: 'Full name',
        add: 'Add',
        title: 'Title',
        creating: 'Creating',
        editing: 'Editing',
        confirm: 'Confirm',
        deleteAnswer: 'Really delete?',
        sport: 'Sport',
        adventures: 'Adventures',
        school: 'School',
        charter: 'Charter',
        management: 'Management',
        description: 'Description',
    },
    inputs: {
        sailingExperience: {
          label: 'Level of experience *your experience may be approximate',
          required: 'Pick your sailing experience',
          defaultValue: 'Your experience:',
          '0/7': 'Beginner',
          '1/7': 'Experienced',
          '2/7': 'Advanced',
          '3/7': 'I’m on all courses and I can dock',
          '4/7': 'perfect technique in detail',
          '5/7': 'the wind and the situation on the water',
          '6/7': 'preparing to win the race',
          '7/7': 'sailing master, learning strategy and tactics',
          'travel0/7': 'never been on a yacht',
          'travel1/7': 'jerked the sheets, held the wheel',
          'travel2/7': 'participated in races',
          'travel3/7': 'was at the regatta ',
          'travel4/7': 'chartman',
        },
        validation: {
            required: "Field is required",
            onlyNumbers: "Invalid symbols only: numbers",
            minNum: "Number lower than {{count}}"
        },
        nominal: {
            label: "Nominal"
        },
        active: {
            label: "Active"
        },
        saleAvailable: {
            label: "Sale available"
        },
        required: 'Required',
        optional: 'Optional',
        hidden: 'Hidden',
        hide: 'Hide',
        email: {
          label: 'Email',
          pattern: 'Enter an existing email address'
        },
    },
    routes: {
        bases: "Bases",
        adventures: "Adventures",
        russia: "Russia",
        world: "World",
        school: "School",
        theory: "Theory",
        practices: "Practices",
        lectures: "Lectures",
        kids_school: "Kids school",
        administration: "Administration",
        orders: "Orders",
        instructors: "Instructors",
        captains: "Captains",
        promocodes: "Promocodes",
        certificates: "Gift Card",
        violations: "Violations",
        feedback: "Feedback",
        promocodes_packages: "Promocodes packages",
        exports: "Exports",
        users: "Users",
        links_generator: "Links generator",
        directories: "Directories",
        subscriptions: "Sailing pass",
        difficulties: "Difficulties",
        user_fields: "User Fields",
        user_fields_templates: "User Fields Templates",
        currencies: "Currencies",
        boats_classes: "Boats Classes",
        shirt_sizes: "Shirt sizes",
        coordinators: "Coordinators",
        base_addresses: "Base addresses",
        public_contracts: "Public contracts",
        extra_materials: "Extra Materials",
        legal_entities: "Legal entities",
        checks: "Checks",
        constructor: "Constructor",
        projects: "Projects",
        templates: "Templates",
        pages: "Pages",
        letters: "Letters",
        files: "Files",
        edit: 'Editing',
        create: 'Creating',
        corp_clients: 'Links for corp',
        transaction_status: 'Transaction status',
        camps: 'Camps',
        materials: 'Materials',
        frontendRoles: 'Admin Panel Roles',
        charters: 'Charter',
        constructorDirectory: 'Directory',
        baseAddresses: 'Base addresses',
        corp_users: 'Corp. clients',
        areas: 'Areas',
        global_settings: 'Global settings',
        separate_functionality: 'Separate functionality',
        formats: 'Formats'
    },
    management: {
        breadcrumbsAndTitle: {
            management: "Management",
            blacklist: "Blacklist",
            create: "Create",
            view: "Viewing",
            review: "Reviews",
            edit: "Editing",
            purchases: "Orders",
            captains: "Captains",
            users: "Users",
            instructors: "Instructors",
            editInstructor: "Editing the instructor",
            promoCodePackages: "Promo code packages",
            promoCodes: "Promo codes",
            certificates: "Gift Card",
            export: "Export",
            linksGenerator: "Link Generator",
            materials: 'Materials',
            frontendRoles: 'Frontend Roles'
        },
        certificates: {
            list: {
                all: "All",
                active: "Active",
                inactive: "Inactive",
                isActive: "Is active",
                create: "Create",
                modalTitle: "Archive the certificate",
                deleteText: "Are you sure?",
                confirmButtonText: "Yes",
                titleInputLabel: "Title",
                ownerInputLabel: "Owner",
                ownerInputPlaceholder: "Name, surname, email, phone",
                senderInputLabel: "Sender",
                productTypeInputLabel: "Product type",
                stateInputLabel: "State",
                manager: "Manager",
                ownerName: "Owner name",
                title: "Title",
                product: "Product",
                remains: "Remains",
                sum: "Sum",
                ownerPhone: "Owner phone",
                ownerMail: "Owner mail",
                senderMail: "Sender mail",
                ownerInputPhoneHint: 'To find the client’s phone number - enter the number in any format or part of the number without the country code (without +971).',
                ownerInputNameHint: 'You can also search by Surname or Name.'
            },
            changeModal: {
                header: "Changing the certificate",
                sizeInputLabel: "Certificate amount",
                sizeLeftInputLabel: "Certificate balance",
                cancel: "Cancel",
                ok: "Ok"
            },
            certificateStatus: {
                size: "Amount",
                sizeLeft: "Balance",
                changeState: "Change state"
            },
            queueStatus: {
                status: "Queue status",
                notDefined: "not defined",
                dateOfLastAction: "Date of last action",
                lastRecipientEmail: "Last recipient's email",
                error: "Error (if there was one)"
            },
            tabsGeneralIndex: {
                certificateParameters: "Certificate parameters",
                paymentParameters: "Payment parameters",
                copyCode: "Copy the code",
                purchasePage: "Purchase page"
            },
            tabsGeneralParameters: {
                russia: "Russia",
                belarus: "Belarus",
                uae: "UAE",
                requiredField: "The field is required",
                recipientMail: "Recipient's email",
                recipientName: "Recipient's name",
                recipientSurname: "Recipient's last name",
                recipientPhone: "Recipient's phone number",
                recipientNamePlaceholder: "First name",
                recipientSurnamePlaceholder: "Last name",
                productType: "Product Type",
                certificateType: "Certificate Type",
                activityDate: "Activity date",
                countryOfApplication: "Country of application",
                nominalValue: "Nominal value",
                buyerMail: "Buyer's mail",
                realMail: "Can I have a real one",
                comment: "Client comment",
                commentHint: "45 characters is the limit",
                commentPlaceholder: "Good wind!",
                commentMaxLength: "More than 45 characters.",
                managerComment: "Manager comment",
                managerCommentPlaceholder: "Manager comment...",
                minError: 'Value must be greater than 0',
            },
            tabsPaymentInfo: {
                statusChanged: "Payment status changed",
                certificateAddedToQueue: "Certificate submission has been successfully added to the queue!",
                invoiceHasBeenGenerated: "The invoice has been generated!",
                invoiceAddedToQueue: "The invoice has been successfully added to the queue!",
                cancelPayment: "Cancel payment",
                toPay: "To pay",
                generateAnInvoice: "Generate an invoice",
                orderHasAlreadyBeenPaid: "The order has already been paid for",
                paymentLink: "link to payment",
                validUntil: "valid until",
                send: "Send",
                sendToRecipient: "Send to recipient",
                sendToBuyer: "Send to the buyer",
                downloadFile: "Download file",
                hasPaid: "Paid for",
                sendCertificateToRecipient: "Send the certificate to the recipient"
            },
            tabList: {
                generalData: "General data",
                historyOfChanges: "History of changes",
                queueHistory: "Queue history"
            },
            history: {
                purchase: "Purchase",
                manual: "Manual",
                operatorDownload: "The manager downloaded the file",
                typeChange: "Type changed",
                change: "Change",
                noChange: "No change",
                changeReason: "Reason",
                whoApplied: "Who applied",
                product: "Product",
                time: "Time",
                comment: "Comment",
                add: "Add",
                commentPlaceholder: "Manager comment...",
                update: "Updated",
                create: "Created",
                changes: {
                  assignee_comment: "Assignee comment",
                  assignee_email: "Assignee email",
                  assignee_full_name_search: "Assignee full name search",
                  assignee_name: "Assignee name",
                  assignee_phone: "Assignee phone",
                  assignee_surname: "Assignee surname",
                  available_from: "Available from",
                  available_to: "Available to",
                  country: "Country",
                  is_active: "Is active",
                  is_created_manually: "Is created manually",
                  name: "Name",
                  price_currency: "Currency",
                  product_type: "Product type",
                  queue_action_date: "Queue action date",
                  queue_action_email: "Queue action email",
                  queue_error_message: "Queue error message",
                  queue_status: "Queue status",
                  send_if_paid: "Send if paid",
                  senders_email: "Senders email",
                  size: "Size",
                  size_left: "Size left",
                  type: "Type",
                }
            },
            certificateIndex: {
                success: "Successfully!",
                editing: "Editing",
                creating: "Creation",
                certificate: "certificate",
                save: "Save",
                cancel: "Cancel"
            }
        },
        export: {
            title: "All export files",
            entity: "Entity",
            progress: "Progress",
            status: "Status",
            file: "File",
            user: "User",
            description: "Description",
            date: "Date",
            statusReady: "ready",
            statusInProgress: "in progress",
            statusError: "error",
            lines: "lines"
        },
        feedback: {
            titleReview: "Viewing review",
            added: "Left",
            product: "Product",
            mail: "Mail",
            phone: "Phone",
            evaluation: "Evaluation",
            evaluationFrom: "Evaluation \"from\"",
            evaluationTo: "Evaluation \"to\"",
            name: "Name",
            surname: "Surname",
            firstLastName: "First and last name",
            comment: "Comment",
            backButton: "Back",
            chooseProduct: "Choose a product",
            productType: "Product Type",
            date: "Date",
        },
        instructors: {
            editModal: {
                instructor: "Instructor",
                createNewInstructor: "Create a new instructor",
                instructorData: "Instructor data",
                chooseFromExisting: "Choose from existing",
                client: "Client",
                clientSelected: "The client is selected",
                goToClientRecord: "Go to the client record",
                success: "Success",
                createInstructor: "Creating an Instructor",
                ok: "Ok",
                cancel: "Cancel",
                requiredField: "The field is required",
                patronymic: "Patronymic",
                patronymicPlaceholder: "Ivanovich",
                instructorMail: "Instructor's email",
                fileHint: "The file size is not more than 3 MB",
                avatar: "Avatar",
                chooseBoatClasses: "Choose boat classes",
                boatClass: "Boat classes",
                wellDone: "Well done!",
                comment: "Comment",
                chooseBase: "Select a base",
                attachToBase: "Attached to the base",
                status: "Status",
                chooseStatus: "Select the status",
                active: "Active",
                inactive: "Inactive",
                ill: "Ill",
                name: "Name",
                surname: "Surname",
                surnamePlaceholder: "Ivanov",
                namePlaceholder: "Ivan",
            },
            instructor: {
                instructor: "Instructor",
                instructorData: "Instructor data",
                schedule: "Schedule",
                success: "Success",
                ok: "Ok",
                goToClientRecord: "Go to the client record",
                date: "Date",
                time: "Time",
                boat: "A boat",
                whoBooked: "Who booked",
                slot: "Slot"
            },
            instructorList: {
                instructor: "Instructor",
                active: "Active",
                inactive: "Inactive",
                ill: "Ill",
                allBases: "All bases",
                allYachtClasses: "All yacht classes",
                searchByInstructors: "Search by instructors",
                status: "Status",
                comment: "Comment",
                yachtClasses: "Yacht classes",
                attachedToBase: "Attached to the database"
            }
        },
        linksGenerator: {
            events: "Events",
            linksHistory: "Link history",
            link: "Link",
            sport: "Sport",
            theory: "Theory",
            enterField: "Enter one of the fields",
            base: "Base",
            choose: "Choose...",
            eventType: "Event type",
            boardType: "Type of boat",
            followLink: "Follow the link...",
            generate: "Generate",
            activityType: "Type of activity",
            notAvailableOnline: "Not available online",
            city: "City",
            eventFormat: "Format of the event",
            online: "Online",
            regular: "Regular",
            offline: "Offline",
            intensive: "Intensive",
            product: 'Product',
        },
        orders: {
            orderTable: {
                transferToAnotherProduct: "Transfer to another product",
                goToUserProfile: "Go to User profile",
            },
            deleteButton: {
                delete: "Remove",
                confirmButtonText: "Yes, delete",
                confirmText: "Are you sure you want to remove a person from the order?",
                confirmTitle: "Cancellation of part of the order",
                deleteFromOrder: "Remove from Order"
            },
            customFormControls: {
                instructor: "Instructor",
                anyoneCool: "Anyone cool",
                places: "places",
            },
            orderList: {
                paidLocally: "Paid locally",
                noPayment: "No payment",
                rbk: "RBC",
                fondy: "Fondi",
                tinkoff: "Tinkoff",
                cloudPayments: "Cloudpayments",
                alpha: "Alpha",
                promoCode: "Promo code",
                free: "Is free",
                subscription: "Subscription",
                certificate: "Certificate",
                manual: "Manual",
                paidFor: "Paid for",
                notPaid: "Not paid",
                promoCodeCopied: "The name of the promo code has been copied",
                subscriptionCopied: "The name of the subscription has been copied",
                discountGeneral: "discount: general",
                individual: "individual",
                probablyFraudster: "Probably a fraudster",
                soloDiscount: "Discount for booking without an instructor",
                fullBoardDiscount: "Discount for booking a full boat",
                any: "Any",
                card: "By card",
                byPromoCode: "Promo code",
                bySubscription: "Subscription",
                create: "Create",
                name: "Name",
                mail: "Mail",
                queue: "In the queue",
                yes: "Yes",
                no: "No",
                currency: "Currency",
                paymentType: "Payment time",
                productType: "Product Type",
                product: "Product",
                slot: "Slot",
                arrival: "Arrival",
                theory: "Theory",
                lecture: "Lecture",
                stripe: 'Stripe',
                transactionStatus: 'Status',
                byCash: 'By cash',
            },
            order: {
                userComment: "User's comment",
                discountInfo: "Information about discounts",
                promoCode: "Promo code",
                promoCodeCopied: "The name of the promo code has been copied",
                certificate: "Certificate",
                certificateCopied: "The name of the certificate has been copied",
                subscription: "Subscription",
                subscriptionCopied: "The name of the subscription has been copied",
                paymentTime: "Payment time",
                productUrl: "Product URL",
                productType: "Product Type",
                slots: "Slots",
                arrivals: "Arrivals",
                groups: "Groups",
                subscriptions: "Subscriptions",
                currency: "Currency",
                price: "Price",
                entered: "Entered",
                percent: "Percent",
                paid: "Paid for",
                inQueue: "In the queue",
                pay: "To pay",
                cancelPayment: "Cancel payment",
                paidFor: "Paid for",
                addCustomersToPayment: "Add customers to the payment",
                assignPayer: "Assign a payer",
                save: "Save",
                paymentStatusChanged: "Payment status changed",
                close: "Close",
                selectClient: "Select a client",
                payInFull: "Pay in full",
                money: "Money"
            },
            statistic: {
              slots: 'Trainings',
              arrivals: 'Adventures and Practices',
              groups: 'Groups',
              count: 'Orders count',
              canceledRefunded: 'Canceled/Refunded count',
              moved: 'Moved count',
            },
            passportsModal: {
              passExists: 'Passport',
              title: 'Passports'
            }
        },
        promoCodePackages: {
            create: "Create",
            success: "Successfully!",
            packageSetup: "Package Setup",
            packageName: "Package Name",
            prefix: "Prefix",
            requiredField: "The field is required",
            packageSize: "Package Size",
            setupPromoCode: "Setting up a promo code",
            searchPromoCodes: "Find promo codes",
            confirmDelete: "Yes, we are deleting",
            deleteAnswer: "Are you sure you want to delete the package?",
            deleteTitle: "Deleting a package",
            creator: "Creator"
        },
        promoCodes: {
            showArchive: "Show archive",
            useForAbonements: "Use promocodes for abonements",
            create: "Create",
            edit: "Edit",
            ofPromoCode: "promo code",
            promoCodeName: "Promo code name",
            startDate: "Start date",
            endDate: "End date",
            indefinite: "indefinite",
            usingMax: "Using max",
            totalUsages: "Total usages",
            repaid: "Repaid",
            archived: "Archived",
            allProducts: "All products",
            adventures: "Adventures",
            adventuresRus: "Adventures: in Russia",
            adventuresWorld: "Adventures: Around the world",
            allBases: "All Bases ( + events and slots )",
            events: "Events: ",
            eventsHike: "Events: Hiking",
            captainsSchool: "School of Captains",
            courseTheory: "Course: Theory",
            coursePractice: "Course: Practice",
            captainsLecture: "Course: lecture hall",
            comment: "Comment",
            commentDesc: "Write what the promo code is being created for",
            typeOfNameGeneration: "Type of name generation",
            manual: "Manual",
            combined: "Semi-automatic",
            automatic: "Automatic",
            basesForAttachment: "Bases for attachment",
            chooseBases: "Select bases",
            chooseConditions: "Select conditions",
            andOr: "AND (OR)",
            chooseProduct: "Choose a product",
            whatIsAppliedTo: "What is applied to",
            phone: "Phone",
            currency: "Currency",
            requiredField: "The field is required",
            sum: "Sum",
            percent: "Percent",
            type: "Type",
            enoOfPromoCode: "The end of the promo code",
            openEnd: "Open end",
            startOfPromoCode: "The beginning of the promo code",
            title: "Title",
            success: "Successfully!",
            ofAdventures: "adventures",
            ofArrivals: "arrivals",
            ofEvents: "events",
            ofGroups: "groups",
            ofSlots: "slots",
            promoCodeProductTypeDescription: "This promo code is used for a separate product",
            usages: 'usages',
            usageType: 'Usage type',
            normal: 'Normal',
            welcome: 'Welcome',
            disposable: 'Disposable',
            creator: 'Creator',
            camps: 'All camps',
            campsRus: 'Camps: in Russia',
            campsWorld: 'Camps: Around the world',
            specialConditions: 'Special conditions and mechanics',
            specialConditionsInfo: 'Promocode can only be applied when using Tinkoff authorization',
            subscriptions: 'Subscriptions',
        },
        users: {
            chooseRole: "Choose a role",
            surnamePlaceholder: "Ivanov",
            namePlaceholder: "Ivan",
            patronymicPlaceholder: "Ivanovich",
            create: "Create",
            editing: "Editing",
            creating: "Creating",
            user: "user",
            name: "Name",
            surname: "Surname",
            nameAndSurname: "Name and Surname",
            patronymic: "Patronymic",
            mail: "Mail",
            phone: "Phone",
            blocked: "Blocked",
            role: "Role",
            yes: "Yes",
            no: "No",
            registered: "Registered",
            insured: "Insured",
            nameOrSurname: "First / Last Name",
            data: "Data",
            technicalData: "Technical data",
            purchaseHistory: "Purchase history",
            subscription: "Subscription",
            eventsHistory: "Event history",
            changeHistory: "History of changes",
            cancel: "Cancel",
            save: "Save",
            phoneAlreadyExist: "Such a phone is already in use!",
            success: "Successfully!",
            subscriptionChangeTitle: "Changing the subscription",
            subscriptionSum: "Subscription amount",
            subscriptionIntValidation: "There should only be numbers",
            notSelected: "Not selected",
            comment: "Comment",
            ok: "Ok",
            purchaseSemicolon: "Purchase; ",
            rechargeSemicolon: "Replenishment; ",
            manualSemicolon: "Manual change; ",
            size: "Amount",
            totalDiscount: "Discount (total)",
            individualDiscount: "Discount (individual)",
            sailGPDiscount: "Discount (GP)",
            racingDiscount: "Discount (cup)",
            change: "Change",
            changeMagnitude: "The magnitude of the change",
            changeReason: "Reason for the change",
            operator: "Operator",
            product: "Product",
            time: "Time",
            booking: "Booking",
            paymentApproved: "Payment approved",
            refund: "Refund",
            paymentDeclined: "Payment declined",
            postpone: "Time transfer",
            moved: "Rescheduling a purchase",
            removedFromPayment: "Removed from payment",
            action: "Action",
            reason: "Reason",
            purchase: "purchase",
            requiredField: "The field is required",
            shirtSize: "T-shirt Size",
            nameEng: "Name in English",
            surnameEng: "Last name in English",
            birthDate: "Date of birth",
            passportNumRu: "Passport of the Russian F. - Series/number",
            passportDateRu: "Passport of the Russian F. - Date of issue",
            passportAuthoritiesRu: "Passport of the Russian F. - Issued by whom",
            passportNumForeign: "ID/ Passport - Series/number",
            passportDateForeign: "ID/ Passport - Valid until",
            citizenship: "Citizenship",
            gender: "Gender",
            male: "m",
            female: "f",
            sailingCardId: "Yacht card ID",
            experience: "Experience",
            characteristic: "Characteristic",
            country: "Country of residence",
            countryPlaceholder: "Russia",
            city: "City",
            cityPlaceholder: "Moscow",
            additionalInfo: "Additional information",
            additionalInfoPlaceholder: "Pushkin, 12",
            password: "Password",
            minLengthMessage: "The minimum length is 5 characters",
            authorizationService: "Authorization service",
            hasLoyalDiscount: "50 euro discount",
            hasBlocked: "Blocked",
            confirmedMail: "Confirmed email",
            serviceRecord: "Service record",
            sendLetters: "Send emails",
            onlyMasterOrders: "Master orders only",
            unsubscribed_news: "Unsubscribe from news",
            is_corp: "Corp client",
            sailingExperience: {
              '0/7': 'Beginner',
              '1/7': 'Experienced',
              '2/7': 'Advanced',
              '3/7': 'I’m on all courses and I can dock',
              '4/7': 'perfect technique in detail',
              '5/7': 'the wind and the situation on the water',
              '6/7': 'preparing to win the race',
              '7/7': 'sailing master, learning strategy and tactics',
            },
            frontendRole: 'Admin Panel Role',
            insuranceId: 'Insurande Id',
            insurance: 'Insurance',
            insuranceHistory: 'Insurance History',
        },
        violations: {
            blacklist: 'Blacklist',
            selectedUser: "Selected user",
            added: "Added",
            createButton: "Create",
            backButton: "Back",
            description: "Description",
            name: "Name",
            namePlaceholder: "Ivan",
            surname: "Surname",
            surnamePlaceholder: "Ivanov",
            patronymic: "Patronymic",
            patronymicPlaceholder: "Ivanovich",
            mail: "Mail",
            product: "product",
            notSelected: "not selected",
            chooseProduct: "Choose a product",
            productType: "Product Type",
            violation: "Violation",
            date: "Date",
            manager: "Manager"
        },
        frontRoles: {
            roles: 'Which roles can be assigned',
            rolesInfo: 'The role you create or edit can be nominated for protection below',
            rules: 'Client rules',
            item: 'Name of new role',
        }
    },
    frontRoles: {
        breadcrumbs: {
            bases: 'Bases',
            group: 'Breadcrumbs',
        },
        certificates: {
            bases: 'Bases',
            group: 'Certificates',
            item: {
                edit: 'Edit and create',
                tab: {
                    history: 'View (change history)',
                }
            }
        },
        promocodes: {
            group: 'Promocodes',
            item: {
                edit: 'Edit and create'
            }
        },
        bases: {
            list: {
                row: {
                    gear: 'Edit base (gear)'
                }
            },
            item: {
                instructors: 'Instructors'
            },
            group: 'Bases',
        },
        base: {
            events: {
                export: 'Event export',
                creation: 'Event creation',
            },
            slots: {
                list: {
                    row: {
                        hide: 'Hide slot',
                        boats: 'Slot boats',
                        close: 'Close slot',
                        remove: 'Remove slot',
                        instructors: 'Slot instructors',
                    }
                }
            },
            group: 'Base',
        },
        event: {
            item: {
                edit: 'Event edit',
                export: 'Event export',
                slots: {
                    creation: 'Slot creation',
                    list: {
                        row: {
                            remove: 'Slot remove'
                        }
                    }
                }
            },
            group: 'Event',
        },
        slot: {
            item: {
                boats: 'Boats',
                tabs: {
                    general: 'Tab general',
                    promos: 'Tab promos',
                    instructors: 'Tab instructors',
                },
                orders: {
                    creation: 'Order creation',
                    list: {
                        row: {
                            edit: 'Order edit',
                            remove: 'Order remove',
                            transfer: 'Order transfer',
                        }
                    }
                }
            },
            group: 'Slot'
        },
        sidebar: {
            bases: 'Bases',
            charter: 'Charter',
            school: 'School',
            adventures: 'Adventures',
            constructor: 'Constructor',
            camps: {
                world: 'Camps world',
                russia: 'Camps russia',
            },
            management: {
                users: 'Users',
                orders: 'Orders',
                promos: 'Promos',
                reviews: 'Reviews',
                exports: 'Exports',
                blacklist: 'Blacklist',
                materials: 'materials',
                instructors: 'Instructors',
                certificates: 'certificates',
                promopackages: 'promopackages',
                linksgenerator: 'linksgenerator'
            },
            directory: {
                offers: 'Offers',
                merchants: 'Merchants',
                corpclients: 'Corp clients',
                transstatus: 'Trans status',
                boatclasses: 'Boat classes',
                tshortsizes: 'T-Shirt sizes',
                customfields: 'Custom fields',
                subscriptions: 'Subscriptions',
                extramaterials: 'Extra materials',
                difficultyscales: 'Difficulty scales',
                templatescustomfields: 'Template custom fields',
                checks: 'Checks'
            },
            group: 'Sidebar'
        },
        practise: {
            list: {
                item: {
                    remove: 'Practise remove',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Arrival remove',
                        }
                    }
                }
            },
            group: 'Practise'
        },
        adventures: {
            list: {
                item: {
                    remove: 'Adventure remove',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Arrival remove',
                        }
                    }
                }
            },
            group: 'Adventures'
        },
        charter: {
            list: {
                item: {
                    remove: 'Charted adventure remove',
                }
            },
            item: {
                arrivals: {
                    list: {
                        row: {
                            remove: 'Charted arrival remove',
                        }
                    }
                }
            },
            group: 'Charter'
        },
        theory: {
            list: {
                row: {
                    remove: 'Theory remove'
                }
            },
            group: 'Theory'
        },
        lecture: {
            list: {
                row: {
                    remove: 'Lecture'
                }
            },
            group: 'Lecture'
        }
    },
    utils: {
        monday: 'MO',
        tuesday: 'TU',
        wednesday: 'WE',
        thursday: 'TH',
        friday: 'FR',
        saturday: 'SA',
        sunday: 'SU',
    },
    layout: {
        login: 'Login',
        password: 'Password',
        enter: 'Enter',
        exit: 'Exit',
    },
    lib: {
        duplicateEntry: 'Duplicate entry. Please try again',
        validationError: 'Validation error',
        methodNotAllowed: 'Что-то пошло не так :(',
        notFound: 'Not found',
    },
    constructor: {
        label: 'Label',
        key: 'Key',
        keyPlaceholder: 'template_key',
        public: 'Public',
        createdAt: 'Created',
        updatedAt: 'Updated',
        projects: {
            createProject: 'Create project',
            deleting: 'Project deleting',
            areYouSure: 'Are you sure you want to delete project?',
            newProject: 'New project',
            editModalChildren: {
                labelPlaceholder: 'Sberbank'
            }
        },
        extraMaterials: {
            link: "Link",
            name: "Name",
            namePlaceholder: "How to pick name?",
        },
        directoryTemplate: {
            create: "Create",
            search: {
                coordinator: 'Search by coordinator',
                base_addresses: 'Search by base address',
                areas: 'Search by areas',
                formats: 'Search by formats',
            }
        },
        templates: {
            labelPlaceholder: 'Template label',
            create: 'Create template',
            deleting: 'Template deleting',
            areYouSure: 'Are you sure you want to delete template?',
            new: 'New template',
            logo: 'Logo',
            start_date: 'Start date',
            start_time: 'Start time',
            end_time: 'End time',
            instructor_name: 'Instructor name',
            rules_link: 'Rules link',
            commercial_offer: 'Commercial offer',
            presentaion: 'Presentaion',
            formats: 'Formats',
            photos_link: 'Photos link',
            getting_there_link: 'Getting there link',
            coordinator_name: 'Coordinator name',
            coordinator_phone: 'Coordinator personal phone number',
            coordinator_corp_phone: 'Coordinator corporate phone number',
            coordinator_photo: 'Coordinator avatar photo',
            more_link: 'More about trainings',
            duration: 'Duration',
            card_template: 'Invitation',
            phone: 'Phone',
            availableParams: 'Parameters available:',
            what_bring: 'What bring',
            promocode: 'Promo code',
            show_photo: 'Show photo block',
            show_discounts: 'Show discounts',
            show_block_information: 'Information block',
            ya_maps_widget: 'Yandex Maps widget',
            base_addresses: 'Base addresses',
            event_format: 'Format',
            description: 'Description',
            timing: 'Timing',
            auth_button_link: 'Auth button link',
            areas: 'Areas',
        },
        coordinators: {
            name: "Name",
            phone: "Personal phone",
            corp_phone: "Corporate phone",
            type: "Check type",
            namePlaceholder: "Greek vacation",
            chooseType: "Choose type",
            varToUse: "You can use variable:",
            baseVarInfo: " - in the check will be an event base ",
            yachtVarInfo: " - in the check will be a boat ",
            example: "Example: Train on ",
            exampleRes: "In the check will be: Train on ",
        },
        areas: {
            name: "Name",
            description: "Description",
            img: "Images",
            namePlaceholder: "Strogino",
            descriptionPlaceholder: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla voluptates sed minima doloribus eius nobis earum saepe.",
        },
        formats: {
            name: "Name",
            description: "Description",
            img: "Images",
            namePlaceholder: "Strogino",
            descriptionPlaceholder: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla voluptates sed minima doloribus eius nobis earum saepe.",
        },
        baseAddresses: {
            name: "Base name",
            description: "Description",
            imgUrls: "Images",
            city: "City",
            address: "Address",
            building: "Building",
            getting_there_link: "Getting there link",
            namePlaceholder: "Base name 'New York'",
            cityPlaceholder: "New York",
            addressPlaceholder: "Spruce RoadJamestown",
            buildingPlaceholder: "4",
            gettingThereLinkPlaceholder: "https://google.com",
            descriptionPlaceholder: "Description text",
        },
        corpClients: {
            title: "Title",
            slug: "Slug",
            events: "Events"
        },
        pages: {
            labelPlaceholder: 'Page label',
            instructorNamePlaceholder: 'Ivan',
            rulesLinkPlaceholder: 'https://google.com',
            photosLinkPlaceholder: 'https://google.com',
            moreLinkPlaceholder: 'https://google.com',
            coordinatorNamePlaceholder: 'Ivan',
            gettingThereLinkPlaceholder: 'https://google.com',
            promocodePlaceholder: 'windrises',
            yaMapsWidgetPlaceholder: '<iframe></iframe>',
            baseAddressPlaceholder: 'Base name',
            eventFormatPlaceholder: 'Format',
            descriptionPlaceholder: 'Description',
            durationPlaceholder: 'Duration',
            timingPlaceholde: 'Timing',
            project: 'Project',
            pickProject: 'Pick project',
            template: 'Template',
            pickTemplate: 'Pick template',
            deleting: 'Page deleting',
            areYouSure: 'Are you sure you want to delete page?',
            projectKey: 'Project key',
            projectKeyPlaceholder: 'google',
            projectLabel: 'Project label',
            projectLabelPlaceholder: 'Google Corp.',
            cardTemplate: 'Invitation',
            cardTemplatePlaceholder: 'Adventure in Strogino',
            promocode: 'Promo code',
            coordinator: 'Сoordinator',
            yaMapsWidget: 'Yandex Maps widget',
            base_address: 'Base addresses',
            areas: 'Areas',
            event_format: 'Format',
            description: 'Description',
            duration: 'Duration',
            timing: 'Timing',
            formats: 'Formats',
        },
        files: {
            areYouSure: 'Are you sure you want to delete file?',
            deleting: 'File deleting',
            mime: 'Type',
            size: 'Size',
            image: 'Image',
            upload: 'Upload file',
            file: 'File',
        }
    },
    globalSales: {
      form: {
        discountedPrice: "New price",
        startDate: "Start date",
        endDate: "End date",
        title: "Title",
        count: "Remains in sale",
        startTime: "Start time",
        endTime: "End time",
        range: "Range",
        limit: "Limit",
        sold: "Sold with discount",
      },
      creationAlert: "You have an open sale. There can only be one open sale at a time."
    },
    managerComments: {
      placeholder: 'Enter a comment for the manager...',
    }
}